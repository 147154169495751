// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
const spec_Identifier = {__proto__:null,where:12, null:28, and:40, or:42, limit:52, select:66, as:70, render:74, each:76, all:78}
export const parser = LRParser.deserialize({
  version: 14,
  states: ")xOVQPOOOmQQO'#C^QOQPOOOtQQO'#CwOOQO'#DV'#DVO!`QQO,58xOtQQO'#C`OtQQO'#CuO!gQQO'#C|O#RQSO'#DQOOQO'#Cg'#CgOtQQO'#CkOOQO'#Cd'#CdO#^QSO'#CcO&eQSO'#ClOOQO'#Cl'#ClOtQQO'#CnO(]QSO'#CyOOQO'#Cc'#CcO(yQQO'#DrOOQO,59c,59cOOQO-E7T-E7TO)bQSO,58zO)xQSO,59aO*`QSO'#ClO*yQSO'#DOO+QQQO'#DsOOQO,59h,59hOOQO,59l,59lO+iQPO,59lO+nQSO'#D_O+{QPO,59VO,QQQO,59XO,VQQO,59`O,^QSO,59YOtQQO,59^OtQQO,59^OtQQO,59^OOQO'#Cz'#CzOOQO,59e,59eOtQQO,59ZOtQQO,59ZOtQQO'#DXO,eQQO,5:^O,|QQO,59jO!gQQO'#DYO-RQQO,5:_OOQO1G/W1G/WOtQQO'#DWO-jQPO,59yOOQO1G.q1G.qOOQO1G.s1G.sO-uQPO1G.zOOQO1G.z1G.zOOQO1G.t1G.tO/kQSO1G.xOOQO1G.x1G.xO/uQSO1G.xO1sQSO1G.uO1zQSO1G.uOOQO,59s,59sOOQO-E7V-E7VOOQO1G/U1G/UOOQO,59t,59tOOQO-E7W-E7WO2RQSO,59rOOQO-E7U-E7UOOQO7+$f7+$f",
  stateData: "2h~O!POS~ORPO~OUUOjVOlROqWOuXO~O}QX~P[OT^OX[OY[O[YO][O^[O!QZO!V`O~O}Qa~P[OThOX[OY[O[YO][O^[O!QZO!V`O~OvmOwmOxlO~O!UpOUVXdVXeVXgVXjVXlVXoVXqVXuVX}VX!SVX!XVX!YVX!ZVX![VX!]VX!^VX!_VX!`VX!aVX!bVX!cVX!dVX!eVXsVX!TVX!WVX~O!VqOd`Xe`Xg`X!U`X!X`X!Y`X!Z`X![`X!]`X!^`X!_`X!``X!a`X!b`X!c`X!d`X!e`Xs`X~OU`Xj`Xl`Xo`Xq`Xu`X}`X!S`X!T`X!W`X~P%WOdxOeyOgsO!XsO!YsO!ZsO![sO!]sO!^sO!_sO!`sO!atO!btO!ctO!duO!euO~OovOUmXjmXlmXqmXumX}mX!SmX~P'XO!SzOU!fXj!fXl!fXq!fXu!fX}!fX~OUSajSalSaqSauSa}Sa~P'XOUiajialiaqiauia}ia~P'XOUrXjrXlrXqrXurX}rX!SrX~P%WOs|O~P'XO!S}OU!gXj!gXl!gXq!gXu!gX}!gX~Ox!PO~O!S!QO!T!RX!W!RX~P'XO!T!SO~OT!TO~O!W!VO~PtO!W!WO~P'XO!SzOU!faj!fal!faq!fau!fa}!fa~OT!`O~O!S}OU!gaj!gal!gaq!gau!ga}!ga~O!S!QO!T!Ra!W!Ra~O!W!eO~O!atO!btO!ctOUfidfiefigfijfilfiofiqfiufi}fi!Sfi!Xfi!Yfi!Zfi![fi!]fi!^fi!_fi!`fisfi!Tfi!Wfi~O!duO!euO~P-zO!dfi!efi~P-zOgsO!XsO!YsO!ZsO![sO!]sO!^sO!_sO!`sO!atO!btO!ctO!duO!euOUciecijcilciociqciuci}ci!Scisci!Tci!Wci~Odci~P0POdxO~P0PO!Sza!Tza!Wza~P'XOl[goTXo~",
  goto: "&U!hPP!iP!lPP!p#iPP#zPPP#z$]$n#i#iPP#iP#i!lP!lP%P%VP!lP%YP!lPPPP%`%f%l%rPPPP%xPPPPPPPPPPPPPPPPPP&O&RRQOTSPTSaRzQfUQgVSiW}SnZqQr`Q!XsQ!YtQ!ZuQ![xQ!]yR!c!QobRUVWZ`qstuxyz}!Qo[RUVWZ`qstuxyz}!Qo]RUVWZ`qstuxyz}!Qo_RUVWZ`qstuxyz}!QQcRR!^zRwaQjWR!a}QTPReTQ!RnR!d!RQ{cR!_{Q!OjR!b!OQoZR!UqRdRRkW",
  nodeNames: "⚠ Program Query TagIdentifier WhereClause Identifier where Expression Value Number String Bool BooleanKW Regex null List LVal Attribute ParenthesizedExpression LogicalExpression and or BinExpression InKW Call LimitClause limit OrderClause Order OrderBy OrderDirection OrderKW SelectClause select Select as RenderClause render each all PageRef",
  maxTerm: 69,
  skippedNodes: [0],
  repeatNodeCount: 4,
  tokenData: "8k~RzX^#upq#uqr$jrs$}uv%uxy%zyz&Pz{&U{|&Z|}&`}!O&e!O!P&j!P!Q&o!Q![)a!^!_)i!_!`)v!`!a*T!c!}*b!}#O+d#P#Q,a#T#U,f#U#W*b#W#X.c#X#Y*b#Y#Z/S#Z#]*b#]#^2^#^#c*b#c#d3j#d#h*b#h#i7Z#i#o*b#y#z#u$f$g#u#BY#BZ#u$IS$I_#u$Ip$Iq$}$Iq$Ir$}$I|$JO#u$JT$JU#u$KV$KW#u&FU&FV#u~#zY!P~X^#upq#u#y#z#u$f$g#u#BY#BZ#u$IS$I_#u$I|$JO#u$JT$JU#u$KV$KW#u&FU&FV#u~$mP!_!`$p~$uP![~#r#s$x~$}O!`~~%QWOr$}rs%js$Ip$}$Ip$Iq%j$Iq$Ir%j$Ir;'S$};'S;=`%o<%lO$}~%oOY~~%rP;=`<%l$}~%zO!c~~&PO!V~~&UO!W~~&ZO!a~~&`O!d~~&eO!S~~&jO!e~~&oO!U~U&vX!bS]QOY'cZ]'c^!P'c!P!Q(T!Q#O'c#O#P(Y#P;'S'c;'S;=`)Z<%lO'cQ'hX]QOY'cZ]'c^!P'c!P!Q(T!Q#O'c#O#P(Y#P;'S'c;'S;=`)Z<%lO'cQ(YO]QQ(]RO;'S'c;'S;=`(f;=`O'cQ(kY]QOY'cZ]'c^!P'c!P!Q(T!Q#O'c#O#P(Y#P;'S'c;'S;=`)Z;=`<%l'c<%lO'cQ)^P;=`<%l'c~)fPX~!Q![)a~)nP!X~!_!`)q~)vO!Y~~){P!Z~#r#s*O~*TO!_~~*YP!^~!_!`*]~*bO!]~V*iURPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#o*bP+QURP}!O*{!P!Q*{!Q![*{!c!}*{#R#S*{#T#o*{V+iP!QQ!}#O+lT+oTO#P+l#P#Q,O#Q;'S+l;'S;=`,Z<%lO+lT,RP#P#Q,UT,ZOxTT,^P;=`<%l+l~,fO!T~V,mWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#g*b#g#h-V#h#o*bV-^WRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#V*b#V#W-v#W#o*bV.PURPoSTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#o*bV.jWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#X*b#X#Y,f#Y#o*bV/ZVRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#U/p#U#o*bV/wWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#`*b#`#a0a#a#o*bV0hWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#g*b#g#h1Q#h#o*bV1XWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#X*b#X#Y1q#Y#o*bV1zURP[QTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#o*bV2eWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#b*b#b#c2}#c#o*bV3WURPgSTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#o*bV3qWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#f*b#f#g4Z#g#o*bV4bWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#W*b#W#X4z#X#o*bV5RWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#X*b#X#Y5k#Y#o*bV5rWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#f*b#f#g6[#g#o*bV6cVRPTUpq6x}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#o*bU6{P#U#V7OU7RP#m#n7UU7ZOlUV7bWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#f*b#f#g7z#g#o*bV8RWRPTU}!O*b!P!Q*{!Q![*b!c!}*b#R#S*b#T#i*b#i#j1Q#j#o*b",
  tokenizers: [0, 1, 2],
  topRules: {"Program":[0,1]},
  specialized: [{term: 5, get: value => spec_Identifier[value] || -1}],
  tokenPrec: 798
})
